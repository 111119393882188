import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick'
const axios = require('axios');

const SlideShow = () => {

    const [slide, setSlide] = useState([])
    const urlAPI = `https://www.gomispro.com/api/slider/slide.php`
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
          setSlide(res.data)
          //setLoad(true)
          
        }).catch(err => {
          console.log(err)
        })
      }, [])

      const settings = {
        infinite:true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        cssEase: "linear"
    }

    return (
            <div className="w-full h-96  mt-36 overflow-hidden bg-no-repeat bg-left-bottom bg-cover" style={{backgroundImage:`url(${require("../images/banner/background.jpg").default})`}}>
               <div className='flex flex-col items-center justify-center w-full h-full '>
                <div className='flex flex-col items-center justify-center'>
                    <h1 className='text-5xl font-black text-black'>GOMIS PRO</h1>
                    <h2 className='text-yellow-700'>حمل التطبيق و إستمتع بتجربة فريدة من نوعها </h2>

                </div>
                <div className='flex flex-row my-4'>
                        <Link to={`/android/apk.php`} className="w-auto h-auto px-8 py-2 bg-black text-white rounded-md shadow-sm capitalize flex flex-row mx-2">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48" className='mx-2'>
                              <path fill="#4db6ac" d="M7.705,4.043C7.292,4.15,7,4.507,7,5.121c0,1.802,0,18.795,0,18.795S7,42.28,7,43.091c0,0.446,0.197,0.745,0.5,0.856l20.181-20.064L7.705,4.043z"></path><path fill="#dce775" d="M33.237,18.36l-8.307-4.796c0,0-15.245-8.803-16.141-9.32C8.401,4.02,8.019,3.961,7.705,4.043l19.977,19.84L33.237,18.36z"></path><path fill="#d32f2f" d="M8.417,43.802c0.532-0.308,15.284-8.825,24.865-14.357l-5.601-5.562L7.5,43.947C7.748,44.038,8.066,44.004,8.417,43.802z"></path><path fill="#fbc02d" d="M41.398,23.071c-0.796-0.429-8.1-4.676-8.1-4.676l-0.061-0.035l-5.556,5.523l5.601,5.562c4.432-2.559,7.761-4.48,8.059-4.653C42.285,24.248,42.194,23.5,41.398,23.071z"></path>
                        </svg>
                                            
                                            Android
                        </Link>

                        <Link to={''} className="w-auto disabled-link h-auto px-8 py-2 bg-black text-white rounded-md shadow-sm capitalize flex flex-row mx-2">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 26 26" className='mx-2'>
                                    <path fill="#fff" d="M 23.933594 18.945313 C 23.335938 20.269531 23.050781 20.863281 22.28125 22.03125 C 21.210938 23.667969 19.695313 25.707031 17.820313 25.71875 C 16.15625 25.734375 15.726563 24.632813 13.464844 24.652344 C 11.203125 24.660156 10.730469 25.738281 9.0625 25.722656 C 7.191406 25.707031 5.757813 23.867188 4.683594 22.238281 C 1.679688 17.664063 1.363281 12.300781 3.21875 9.449219 C 4.53125 7.425781 6.609375 6.238281 8.5625 6.238281 C 10.546875 6.238281 11.796875 7.328125 13.441406 7.328125 C 15.035156 7.328125 16.003906 6.234375 18.304688 6.234375 C 20.039063 6.234375 21.878906 7.179688 23.191406 8.816406 C 18.894531 11.167969 19.59375 17.304688 23.933594 18.945313 Z M 16.558594 4.40625 C 17.394531 3.335938 18.027344 1.820313 17.800781 0.277344 C 16.433594 0.371094 14.839844 1.242188 13.90625 2.367188 C 13.0625 3.394531 12.363281 4.921875 12.636719 6.398438 C 14.125 6.445313 15.664063 5.558594 16.558594 4.40625 Z"></path>
                                </svg>
                                            
                                            iPhone
                        </Link>
                </div>

                <h2 className='text-red-600'>النسخة الخاصة بأجهزة الأيفون ستكون متوفرة بعد بضعة أسابيع</h2>
               </div>
            </div>
    )
}

export default SlideShow
